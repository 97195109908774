import { withTheme } from '@emotion/react'
import React from 'react'
import BannerStyles from './Banner.styles'
import GlobalCss from './GlobalCss.styles'
import HeaderStyles from './Header.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <BannerStyles />
    <HeaderStyles />
  </>
))

export default GlobalStyles
