import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const BannerCss = withTheme(() => (
  <Global
    styles={css`
      .cta-banner {
        &.only-cta {
          .leshen-link {
            margin-top: 0 !important;
          }
        }
      }
    `}
  />
))

export default BannerCss
