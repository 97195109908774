import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const GlobalCss = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-typography-h1,
      .leshen-typography-h2,
      .leshen-typography-h3,
      .leshen-typography-h4,
      .leshen-typography-h5,
      .leshen-typography-h6 {
        font-weight: 700 !important;
        margin-bottom: ${theme.spacing.md};
      }

      .leshen-typography-body {
        font-weight: 400 !important;
        margin-bottom: ${theme.spacing.md};
      }
    `}
  />
))

export default GlobalCss
