// this needs to be removed when the next version of leshen goes out
// currently on 14.6.7
import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const HeaderCss = withTheme(() => (
  <Global
    styles={css`
      .top-bar {
        justify-content: center !important;
      }
    `}
  />
))

export default HeaderCss
